import type { Alert, AlertId, AlertType } from '~theme/types/alert';
import { keyByFile } from '~theme/utils/keyByFile';

export const ALERT_TYPES = {
  NOTICE: '',
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
} as const;

export const useAlert = () => {
  const alert = useState<Alert>(keyByFile(import.meta, 'alert'), () => ({
    items: [],
    latestId: 0,
  }));

  const push = (message: string, type: AlertType) => {
    alert.value.items = [
      ...alert.value.items,
      {
        id: alert.value.latestId++,
        message,
        type,
      },
    ];
  };

  const _delete = (id: AlertId) => {
    alert.value.items = alert.value.items.filter((item) => item.id !== id);
  };

  const _clear = () => {
    alert.value.items = [];
  };

  return {
    data: computed(() => alert.value),
    delete: _delete,
    clear: _clear,
    notice: (message: string) => {
      push(message, ALERT_TYPES.NOTICE);
    },
    warning: (message: string) => {
      push(message, ALERT_TYPES.WARNING);
    },
    success: (message: string) => {
      push(message, ALERT_TYPES.SUCCESS);
    },
    error: (message: string) => {
      push(message, ALERT_TYPES.ERROR);
    },
  };
};
